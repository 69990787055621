import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="daterangepicker"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract('days', 1), moment().subtract('days', 1)],
      'Last 7 Days': [moment().subtract('days', 6), moment()],
      'Last 30 Days': [moment().subtract('days', 29), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract('month', 1).startOf('month'), moment().subtract('month', 1).endOf('month')],
      'Last 365 Days': [moment().subtract('days', 364), moment()],
    }

    let params = new URLSearchParams(document.location.search);
    let created_at_between = params.get("created_at_between");

    let start_date = moment().subtract('days', 364);
    let end_date = moment();

    if (created_at_between) {
      start_date = moment(created_at_between.split(" - ")[0], 'DD/MM/YYYY');
      end_date = moment(created_at_between.split(" - ")[1], 'DD/MM/YYYY');
    }

    if (this.hasInputTarget) {
      new DateRangePicker(this.inputTarget, {
        startDate: start_date,
        endDate: end_date,
        alwaysShowCalendars: true,
        showWeekNumbers: true,
        ranges: ranges,
        locale: {
          format: 'DD/MM/YYYY'
        }
      }, () => {
        setTimeout(() => {
          this.element.submit();
        }, 300);
      });
    }
  }
}
