import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "input"]

  search() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      let input_length = this.inputTarget.value.length;

      if (input_length >= 2) {
        this.formTarget.requestSubmit();
      }
    }, 500);
  }
}
