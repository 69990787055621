// var openMenu = document.querySelector('.menu-open');
// var menu = document.querySelector('.menu');

// function toggleMenu() {
// 	menu.classList.toggle('active');
// 	openMenu.classList.toggle('active');
// }

// openMenu.addEventListener('click', function (event) {
// 	toggleMenu();
// 	event.stopPropagation(); 
// });

// document.addEventListener('click', function (event) {
// 	var isClickInsideMenu = menu.contains(event.target);

// 	if (!isClickInsideMenu && menu.classList.contains('active')) {
// 		toggleMenu();
// 	}
// });