document.addEventListener("turbo:load", function () {

	const editButton = document.getElementById('edit-button');

	const cancelButton = document.getElementById('cancel-button');

	const infoCardDefault = document.getElementById('info-card-default');
	const infoCardEdit = document.getElementById('info-card-edit');

	if (editButton) {
		editButton.addEventListener('click', function () {
			infoCardDefault.style.display = 'none';
			infoCardEdit.style.display = 'block';
		});

		cancelButton.addEventListener('click', function () {
			infoCardDefault.style.display = 'block';
			infoCardEdit.style.display = 'none';
		});
	}
});

document.querySelectorAll('.statusSelect').forEach(function(select) {
  select.addEventListener('change', function() {
    var formId = this.dataset.formId;
    document.getElementById(formId).submit();
  });
});

document.querySelectorAll('.datetimeSelect').forEach(function(select) {
  select.addEventListener('change', function() {
		var formId = this.dataset.formId;
    document.getElementById(formId).submit();
  });
});


document.addEventListener("turbo:load", function () {
	const editRequestButtons = document.querySelectorAll('[id^="edit-request-button-"]');
	const cancelRequestButtons = document.querySelectorAll('[id^="cancel-request-button-"]');

	editRequestButtons.forEach(button => {
		button.addEventListener('click', function () {
			const requestId = button.id.split('-').pop();
			const infoRequestDefault = document.getElementById(`info-request-default-${requestId}`);
			const infoRequestEdit = document.getElementById(`info-request-edit-${requestId}`);

			if (infoRequestDefault && infoRequestEdit) {
				infoRequestDefault.style.display = 'none';
				infoRequestEdit.style.display = 'block';
			}
		});
	});

	cancelRequestButtons.forEach(button => {
		button.addEventListener('click', function () {
			const requestId = button.id.split('-').pop();
			const infoRequestDefault = document.getElementById(`info-request-default-${requestId}`);
			const infoRequestEdit = document.getElementById(`info-request-edit-${requestId}`);

			if (infoRequestDefault && infoRequestEdit) {
				infoRequestDefault.style.display = 'block';
				infoRequestEdit.style.display = 'none';
			}
			window.location.reload();
		});
	});
});
