function initComments() {
	document.querySelectorAll('.requests-comment').forEach(function (comment) {
		const btn = comment.nextElementSibling;

		if (btn && btn.classList.contains('more-btn')) {
			if (comment.scrollHeight < 1) {
				btn.style.display = 'none';
			}

			btn.onclick = function () {
				if (this.classList.contains('active')) {
					this.classList.remove('active');
					comment.style.maxHeight = '0px';
				} else {
					this.classList.add('active');
					comment.style.maxHeight = `${comment.scrollHeight}px`;
				}
			};
		}
	});
}

function checkAndInitComments() {
	const comments = document.querySelectorAll('.requests-comment');
	if (comments.length > 0) {
		initComments();
		clearInterval(initCheckInterval);
	}
}

let initCheckInterval = setInterval(checkAndInitComments, 500);

document.addEventListener('turbo:load', () => {
	initComments();
	initCheckInterval = setInterval(checkAndInitComments, 500);
});

document.addEventListener('turbo:frame-load', () => {
	initComments();
	initCheckInterval = setInterval(checkAndInitComments, 500);
});






if (document.querySelectorAll('.file-group')) {
	document.querySelectorAll('.file-group').forEach((element) => {
		let initialMaxHeight;

		function changeMaxHeight() {
			let targetElement = this.closest('.file-group-wrapper');

			this.classList.toggle('active');

			if (targetElement.style.maxHeight === '230px') {
				targetElement.style.maxHeight = initialMaxHeight;
			} else {
				initialMaxHeight = targetElement.style.maxHeight;
				targetElement.style.maxHeight = '230px';
			}
		}

		element.addEventListener('click', changeMaxHeight);
	});
}
